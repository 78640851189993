<template>
	<v-app class="fs_main login">
		<v-container>
			<img src="/img/logo.png" class="logo" />
			<div>
				<div style="margin-bottom: 60px">
					<v-text-field
						v-model="loginId"
						background-color="white"
						clearable
						filled
						label="メールアドレス"
						class="textfield"
					></v-text-field>
					<v-text-field
						label="パスワード"
						class="textfield"
						v-model="loginPassword"
						background-color="white"
						clearable
						:append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
						:rules="[rules.min]"
						:type="passwordShow ? 'text' : 'password'"
						hint="パスワードは8文字以上"
						filled
						@click:append="passwordShow = !passwordShow"
					></v-text-field>
					<div class="button mail" @click="clickEmailLogin()">
						<img src="/img/logo_mail.png" alt="MAIL LOGIN" />
						<p>メールでログイン</p>
					</div>
					<div class="button register" @click="clickEmailRegister()">
						<span>新規登録はこちら</span>
					</div>
				</div>
				<div class="button line" @click="clickLineImage()">
					<img src="/img/logo_line.png" alt="LINE LOGIN" />
					<p>LINEでログイン</p>
				</div>
				<div class="button azure" @click="clickAzureImage()">
					<img src="/img/logo_azure.png" alt="AZURE LOGIN" />
					<p>AZUREでログイン</p>
				</div>
			</div>
		</v-container>
	</v-app>
</template>

<script>
import axios from "axios";
import md5 from "js-md5";

export default {
	created() {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
	},
	mounted() {
		document.querySelector('.v-application').setAttribute("style", "min-height: 100vh !important;");
		if (this.$route.query.activated == "true") {
			alert("アクティベーション完了。引き続きログインして下さい");
		}
	},
	methods: {
		clickEmailLogin() {
			if (this.loginId && this.loginPassword.length >= 8) {
				this.showLoading();
				this.$store.commit("SET_STARTPAGE", true);
				const params = {
					loginId: this.loginId,
					loginPassword: md5(this.loginPassword)
				};
				axios.post(
					"https://" +
						this.config.defaultOriginString +
						"/api/mail-login",
					params
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						// プロフィール情報とtokenを更新する
						this.$store.dispatch("user/setData", res.data.appInfo.user);
						this.$store.dispatch("project/setData", res.data.appInfo.project);
						this.$store.dispatch("places/setData", res.data.appInfo.places);
						this.$store.dispatch("auth/setData", { tokenType: res.data.tokenType, token: res.data.token });
						this.$router.push({ name: "Home" }).catch(() => {});
					} else {
						console.log("Fail: " + JSON.stringify(res));
						alert("メールアドレスとパスワードが一致しません");
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
					alert("原因不明のエラーです");
				});
			} else {
				alert("メールアドレスとパスワードが入力されていないかパスワードの文字数が足りません");
			}
		},
		clickEmailRegister() {
			this.$router.push({ name: "Register" }).catch(() => {});
		},
		clickLineImage() {
			this.$store.commit("SET_STARTPAGE", true);
			location.href =
				"https://" + this.config.defaultOriginString + "/api/line-authorize";
		},
		clickAzureImage() {
			this.$store.commit("SET_STARTPAGE", true);
			location.href =
				"https://" + this.config.defaultOriginString + "/api/azure-authorize";
		},
		showLoading: function () {
			this.$emit("loadShowHide", true);
		},
		hideLoading: function () {
			this.$emit("loadShowHide", false);
		},
	},
	data() {
		return {
			loginId: "",
			loginPassword: "",
			passwordShow: false,
			rules: {
				min: (v) => v.length >= 8 || "パスワードは8文字以上",
			},
		};
	},
};
</script>
